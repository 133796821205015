import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  Box,
} from "@chakra-ui/react";
import React from "react";

const DetailModal = ({ item, isOpen, onClose, watermarkImageUrl }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transaction Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Watermark Wrapper */}
          <Box
            position="relative"
            _before={{
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${"./assets/watermark.jpeg"})`, // Replace with your watermark image URL
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.4,
              zIndex: 0,
            }}
          >
            {/* Beneficiary Details Section */}
            <Text
              fontWeight="bold"
              fontSize="lg"
              mb={3}
              zIndex={1}
              position="relative"
            >
              Beneficiary Details
            </Text>
            <Table
              variant="simple"
              size="sm"
              mb={4}
              zIndex={1}
              position="relative"
            >
              <Tbody>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Name</Text>
                  </Td>
                  <Td>
                    {item?.bank?.beneficiaryName || item?.contact?.name || "NA"}
                  </Td>
                  <Td>
                    <Text fontWeight="bold">Bank Name</Text>
                  </Td>
                  <Td>{item?.bank?.bankName || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">IFSC Code</Text>
                  </Td>
                  <Td>{item?.bank?.ifsc || "NA"}</Td>
                  <Td>
                    <Text fontWeight="bold">Account No.</Text>
                  </Td>
                  <Td>{item?.bank?.accountNo || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Transfer Type</Text>
                  </Td>
                  <Td>{item?.type || "NA"}</Td>
                  <Td>
                    <Text fontWeight="bold">Amount</Text>
                  </Td>
                  <Td>{item?.amount || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Transaction Id</Text>
                  </Td>
                  <Td>
                    {item?.transId ? (
                      <>
                        {item?.transId.substring(0, item?.transId.length / 2)}
                        <br />
                        {item?.transId.substring(item?.transId.length / 2)}
                      </>
                    ) : (
                      "NA"
                    )}
                  </Td>
                  <Td>
                    <Text fontWeight="bold">Service Type</Text>
                  </Td>
                  <Td>express payout</Td>
                </Tr>
              </Tbody>
            </Table>

            <Text
              fontWeight="bold"
              fontSize="lg"
              mb={3}
              zIndex={1}
              position="relative"
            >
              Transaction Details
            </Text>
            <Table variant="simple" size="sm" zIndex={1} position="relative">
              <Thead>
                <Tr>
                  <Th>UTR</Th>
                  <Th>UPI Id</Th>
                  <Th>Reference Id</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{item?.bankReferenceNumber || "NA"}</Td>
                  <Td>{item?.upi?.upiId || "NA"}</Td>
                  <Td>{item?.referenceId || "NA"}</Td>
                  <Td>{item?.onlyDate || "NA"}</Td>
                  <Td>{item?.status || "NA"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DetailModal;
