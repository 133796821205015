import {
  Button,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useZwitchStore } from "../../store/Zwitch";
import { STATUS } from "../../Constant";
import { Table, Tbody, Tr, Td, Thead, Th } from "@chakra-ui/react";

export const VerifyOTP = ({
  bank,
  data,
  closeModal,
  handleToggle,
  setToggleVerifyOtp,
}) => {
  const [otp, setOtp] = useState(null);
  const [transactions, setTransactions] = useState(null); // Renamed to `setTransactions`

  const inputHandler = (otp) => {
    setOtp(otp);
  };

  const {
    bankTransferAction,
    bankTransferStatus,
    bankTransfer,
    upiTransferAction,
    upiTransferStatus,
    upiTransfer,
    resetTransferData,
    resetTransferStatus,
    verifyMpinAction,
    verifyMpinStatus,
    resetMpinStatus,
  } = useZwitchStore((s) => ({
    bankTransferAction: s.bankTransferAction,
    bankTransferStatus: s.bankTransferStatus,
    bankTransfer: s.bankTransfer,
    upiTransferAction: s.upiTransferAction,
    upiTransferStatus: s.upiTransferStatus,
    upiTransfer: s.upiTransfer,
    resetTransferData: s.resetTransferData,
    resetTransferStatus: s.resetTransferStatus,
    verifyMpinAction: s.verifyMpinAction,
    verifyMpinStatus: s.verifyMpinStatus,
    resetMpinStatus: s.resetMpinStatus,
  }));

  const verifyOtp = (e) => {
    e.preventDefault();
    verifyMpinAction({ mpin: otp });
  };

  useEffect(() => {
    if (verifyMpinStatus === STATUS.SUCCESS && bank) {
      resetMpinStatus();
      if (bank?.accountNo) {
        bankTransferAction({
          ...data,
          bankId: bank.id,
          accountNo: bank.accountNo,
        });
      } else {
        upiTransferAction({
          ...data,
          upiId: bank.id,
          upi: bank.upiId,
        });
      }
    }
  }, [
    bank,
    bankTransferAction,
    data,
    resetMpinStatus,
    upiTransferAction,
    verifyMpinStatus,
  ]);

  useEffect(() => {
    // Check if the transfer was successful and update the state with the relevant data
    if (
      bankTransferStatus === STATUS.SUCCESS ||
      upiTransferStatus === STATUS.SUCCESS
    ) {
      resetTransferStatus();
      setTransactions(bankTransfer || upiTransfer); // Correctly set transaction details here
      handleToggle(null);
    }
  }, [
    bankTransfer,
    bankTransferStatus,
    closeModal,
    handleToggle,
    resetTransferStatus,
    setToggleVerifyOtp,
    upiTransfer,
    upiTransferStatus,
  ]);

  const Exit = () => {
    resetTransferStatus();
    resetTransferData();
    closeModal();
    setToggleVerifyOtp(null);
  };

  console.log(transactions, "transactions");

  return (
    <>
      {transactions ? (
        <Flex flexDir={"column"}>
          {/* Watermark Container */}
          <Box
            position="relative"
            _before={{
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${"./assets/watermark.jpeg"})`, // replace with your image URL
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.4,
              zIndex: 0,
            }}
          >
            <Text fontWeight="bold" fontSize="lg" mb={3}>
              Beneficiary Details
            </Text>
            <Table
              variant="simple"
              size="sm"
              mb={4}
              position="relative"
              zIndex={1}
            >
              <Tbody>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Name</Text>
                  </Td>
                  <Td>
                    {transactions?.bank?.beneficiaryName ||
                      transactions?.contact?.name ||
                      "NA"}
                  </Td>
                  <Td>
                    <Text fontWeight="bold">Bank Name</Text>
                  </Td>
                  <Td>{transactions?.bank?.bankName || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">IFSC Code</Text>
                  </Td>
                  <Td>{transactions?.bank?.ifsc || "NA"}</Td>
                  <Td>
                    <Text fontWeight="bold">Account No.</Text>
                  </Td>
                  <Td>{transactions?.bank?.accountNo || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Transfer Type</Text>
                  </Td>
                  <Td>{transactions?.type || "NA"}</Td>
                  <Td>
                    <Text fontWeight="bold">Amount</Text>
                  </Td>
                  <Td>{transactions?.amount || "NA"}</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontWeight="bold">Transaction Id</Text>
                  </Td>
                  <Td>
                    {transactions?.transId ? (
                      <>
                        {transactions?.transId.substring(
                          0,
                          transactions?.transId.length / 2
                        )}
                        <br />
                        {transactions?.transId.substring(
                          transactions?.transId.length / 2
                        )}
                      </>
                    ) : (
                      "NA"
                    )}
                  </Td>
                  <Td>
                    <Text fontWeight="bold">Service Type</Text>
                  </Td>
                  <Td>express payout</Td>
                </Tr>
              </Tbody>
            </Table>
            <Text fontWeight="bold" fontSize="lg" mb={3}>
              Transaction Details
            </Text>
            <Table variant="simple" size="sm" position="relative" zIndex={1}>
              <Thead>
                <Tr>
                  <Th>UTR</Th>
                  <Th>UPI Id</Th>
                  <Th>Reference Id</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{transactions?.bankReferenceNumber || "NA"}</Td>
                  <Td>{transactions?.upi?.upiId || "NA"}</Td>
                  <Td>{transactions?.referenceId || "NA"}</Td>
                  <Td>{transactions?.onlyDate || "NA"}</Td>
                  <Td>{transactions?.status || "NA"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Flex w={"100%"} mt={5} justifyContent={"flex-end"}>
            <Button size={"sm"} colorScheme={"blue"} onClick={Exit}>
              OK
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDir={"column"} align={"center"}>
          <Text fontSize={16} mt={4}>
            Enter the 6-Digit MPin
          </Text>
          <Text fontSize={13}>
            Be careful not to share your MPIN with anyone
          </Text>
          <form onSubmit={verifyOtp} style={{ width: "100%" }}>
            <HStack my={10} w={"100%"} justify={"center"}>
              <PinInput
                w={"100%"}
                otp
                autoFocus
                focusBorderColor={"blue.400"}
                borderColor={"gray.400"}
                onChange={(e) => inputHandler(e)}
              >
                <PinInputField borderColor={"gray.400"} />
                <PinInputField borderColor={"gray.400"} />
                <PinInputField borderColor={"gray.400"} />
                <PinInputField borderColor={"gray.400"} />
                <PinInputField borderColor={"gray.400"} />
                <PinInputField borderColor={"gray.400"} />
              </PinInput>
            </HStack>
            <Flex w={"100%"} mt={5} justifyContent={"space-between"}>
              <Button
                size={"sm"}
                colorScheme={"blue"}
                variant={"outline"}
                onClick={() => setToggleVerifyOtp(null)}
              >
                Back
              </Button>
              <Button
                size={"sm"}
                colorScheme={"blue"}
                type="submit"
                isLoading={
                  verifyMpinStatus === STATUS.FETCHING ||
                  bankTransferStatus === STATUS.FETCHING ||
                  upiTransferStatus === STATUS.FETCHING
                }
              >
                Pay
              </Button>
            </Flex>
          </form>
        </Flex>
      )}
    </>
  );
};
